// airdrop from wu
const airdrop = {
    address: '0x2de64BB453FC15bf900197F8612E494903275Dbd',
    abi: [
        {
                "inputs": [
                        {
                                "internalType": "address",
                                "name": "coin_",
                                "type": "address"
                        }
                ],
                "stateMutability": "nonpayable",
                "type": "constructor"
        },
        {
                "anonymous": false,
                "inputs": [
                        {
                                "indexed": false,
                                "internalType": "address",
                                "name": "account",
                                "type": "address"
                        },
                        {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "peroid",
                                "type": "uint256"
                        },
                        {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "amount",
                                "type": "uint256"
                        }
                ],
                "name": "getRewardEvent",
                "type": "event"
        },
        {
                "inputs": [],
                "name": "admin",
                "outputs": [
                        {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [],
                "name": "coin",
                "outputs": [
                        {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [],
                "name": "feeTo",
                "outputs": [
                        {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [],
                "name": "getLastPeroid",
                "outputs": [
                        {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "uint256",
                                "name": "n",
                                "type": "uint256"
                        },
                        {
                                "internalType": "uint256",
                                "name": "amount",
                                "type": "uint256"
                        },
                        {
                                "internalType": "bytes32[]",
                                "name": "proof",
                                "type": "bytes32[]"
                        }
                ],
                "name": "getReward",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                        }
                ],
                "name": "roots",
                "outputs": [
                        {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "address",
                                "name": "_feeTo",
                                "type": "address"
                        }
                ],
                "name": "setFeeTo",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "address",
                                "name": "_admin",
                                "type": "address"
                        }
                ],
                "name": "setFeeToSetter",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "uint256",
                                "name": "peroid",
                                "type": "uint256"
                        },
                        {
                                "internalType": "bytes32",
                                "name": "root",
                                "type": "bytes32"
                        }
                ],
                "name": "setRoots",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                        },
                        {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                        }
                ],
                "name": "states",
                "outputs": [
                        {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                        }
                ],
                "stateMutability": "view",
                "type": "function"
        },
        {
                "inputs": [
                        {
                                "internalType": "bytes32[]",
                                "name": "proof",
                                "type": "bytes32[]"
                        },
                        {
                                "internalType": "bytes32",
                                "name": "root",
                                "type": "bytes32"
                        },
                        {
                                "internalType": "bytes32",
                                "name": "leaf",
                                "type": "bytes32"
                        }
                ],
                "name": "verify",
                "outputs": [
                        {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                        }
                ],
                "stateMutability": "pure",
                "type": "function"
        }
    ]
}

export {
    airdrop
}

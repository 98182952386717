<template>
  <div class="home">
    <div class="content">
      <div class="img">
          <img src="https://cyberpop-new-page.s3-accelerate.amazonaws.com/nwhome/alen.png" alt="" data-v-13a5dc7c="">
      </div>
      <div class="tip"><a href="https://uutool.cn/excel2json/" target="_blank">将excel表生成json</a>, 复制到里面</div>
      <div class="peroid">输入当前期数: <input type="text" v-model="peroid"></div>
      <div class="text">
        <textarea name="" id="input" cols="30" rows="10" v-model="value"></textarea>
      </div>
      <div class="button" @click="submit">
          发放
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
import { utils } from "ethers";
const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs')
import { Buffer } from "buffer/";
window.Buffer = window.Buffer || Buffer;
import Web3 from 'web3/dist/web3.min.js'
import { airdrop } from '@/tools/contract'


export default {
  name: 'HomeView',
  data(){
    return {
      value: '',
      peroid: "",
    }
  },
  mounted(){
    this.login()
    this.init()
  },
  methods: {
     async init(){
      // const web3 = new Web3(Web3.givenProvider);
      // const { abi, address } = airdrop
      // const contract = new web3.eth.Contract(abi, address)
      // this.peroid = await contract.methods.getLastPeroid().call()
      // console.log(this.peroid);
       let that = this;
       (async function loop(index){
          let res = await axios.get(`https://airdrop.cyberpop.online/getAirDataForPeroid?peroid=${index}`)
          if(res.data.data.length > 0){
            loop(++index)
            return
          }
          that.peroid = index
        })(60)
     },


     async submit(){
        let temp = JSON.parse(this.value)
        console.log(temp);

        //存放到后端
        temp.forEach((item) => {
          if(item.ADDR){
            axios.get(`https://airdrop.cyberpop.online/insertAirData?peroid=${parseInt(this.peroid)}&account=${item.ADDR}&num=${parseInt(item['补偿合计'])}`)
            return
          }
          axios.get(`https://airdrop.cyberpop.online/insertAirData?peroid=${parseInt(this.peroid)}&account=${'0xD8974E136a44Bf79896729AfEF1eD2fD9f4E525B'}&num=${parseInt(item['补偿合计'])}`)
        })


        // 然后存放完成以后 再读取这些数据来生成merkletree
        setTimeout(async () => {
            let overdata = await axios.get(`https://airdrop.cyberpop.online/getAirDataForPeroid?peroid=${this.peroid}`)
            
            console.log(overdata.data.data);

            const leaves = overdata.data.data.map(element => {
                if(element.account){
                    return utils.solidityKeccak256(['uint256', 'address', 'uint256'], [parseInt(this.peroid), element.account, parseInt(element.num)])
                }
                return utils.solidityKeccak256(['uint256', 'address', 'uint256'], [parseInt(this.peroid), '0xD8974E136a44Bf79896729AfEF1eD2fD9f4E525B', 0])
            });
            

            console.log(leaves);

            const merkleTree = new MerkleTree(leaves, keccak256, { hashLeaves: true, sortPairs: true })

            const root = merkleTree.getHexRoot();
            console.log("root:    "+root)

            await this.setRoots(this.peroid, root)
            // if(test)

            const leaf = keccak256(leaves[0]);
            console.log("leaf:        "+leaf.toString("hex"))

            const proof = merkleTree.getHexProof(leaf);
            console.log("proof:     "+proof)

            console.log("结果:" + merkleTree.verify(proof, leaf, root));
        }, 5000);
    },

    setRoots(peroid, root){
        return new Promise((resolve, reject) => {
            const web3 = new Web3(Web3.givenProvider);
            const { abi, address } = airdrop
            const contract = new web3.eth.Contract(abi, address)
            contract.methods.setRoots(peroid, root).send({ from: '0xFD0cd49de3e8526fcE2854b40D9f9ef9c74dFb0f' }).then((res) => {
                resolve(res)
            }).catch((err) => {
                resolve(0)
            })
        })
    },


    login(){
        const ethereum = window.ethereum // Get metamask instance
        return ethereum.request({ method: 'eth_requestAccounts' })
    }


  }
}
</script>

<style lang="less" scoped>
  .home{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    .content{
        .tip{
          margin: 20px 0;
        }
        .peroid{
          margin: 20px 0;
        }
        a{
          color: rgb(134, 134, 233);
        }
        #input{
          width: 400px;
          height: 200px;
          background-color: rgba(255, 255, 255, .5);
        }
        .button{
            width: 100px;
            height: 50px; 
            background-color: rgba(255, 255, 255, .8);
            color: #333;
            line-height: 50px;
            border-radius: 20px;
            margin: 20px auto;
            cursor: pointer;
        }
    }
  }
</style>
